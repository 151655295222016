<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 系统管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>绑定监控点</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-button @click="handleGoBack" plain icon="el-icon-back" size="mini">
          返回上一页</el-button
        >
        <el-button
          @click="handleConfirm"
          plain
          icon="el-icon-download"
          size="mini"
          type="danger"
        >
          保存
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择区域 </span>
          <el-select
            @change="handleAreaChange"
            size="mini"
            v-model="select.areaName"
            placeholder="全部区域"
          >
            <el-option
              v-for="item in options.areaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column type="index" :index="1" label="序号">
        </el-table-column>
        <el-table-column prop="monitoryPointName" label="监控点名称">
        </el-table-column>
        <el-table-column prop="mn" label="MN号"> </el-table-column>
        <el-table-column prop="monitoryPointType" label="监控点类型" sortable>
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称"> </el-table-column>
        <el-table-column prop="areaName" label="区域名称"> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import router from "@/router";
import { getAreaOptions } from "@/api/area";
import { queryAll } from "@/api/monitory-point";
import { bindMonitoryPoint } from "@/api/user";

export default {
  name: "BindMonitoryPoint",
  data() {
    return {
      pollutionCodeInput: "",
      tableData: [],
      options: {
        areaOptions: [],
      },
      select: {
        areaName: "",
      },
      multipleSelection: [],
    };
  },
  created() {
    this.getBindingList();
    this.getOptions();
  },
  methods: {
    handleAreaChange(val) {
      this.select.areaName = val;
      if (this.select.areaName !== "") {
        // 删除之后放到第一位
        this.$_.forEach(this.tableData, (val) => {
          if (val.areaName === this.select.areaName) {
            // 1.找到后删除
            this.$_.pull(this.tableData, val);
            // 2.插入第一个
            this.tableData.unshift(val);
          }
        });
      }
    },
    handleSelectionChange(val) {
      console.log(JSON.stringify(val));
      this.multipleSelection = val;
    },
    handleConfirm() {
      console.log("多选选中:", JSON.stringify(this.multipleSelection));
      const monitoryPointIds = this.$_.map(this.multipleSelection, "id");
      console.log(JSON.stringify(monitoryPointIds));
      const username = this.$route.query.username;
      console.log(username);
      // 请求参数
      const requestBody = {
        username: username,
        monitoryPointIds: monitoryPointIds,
      };
      // 发送请求
      bindMonitoryPoint(requestBody);
    },
    handleGoBack() {
      router.go(-1);
      this.$store.commit("closeCurrentTag", {
        $router: this.$router,
        $route: this.$route,
      });
    },
    // 获取列表, 多选框选中绑定的污染物
    getBindingList() {
      const params = {
        username: this.$route.query.username,
      };
      queryAll(params).then((result) => {
        console.log(JSON.stringify(result));
        this.tableData = result.data;
        // 设置选中
        this.$nextTick(() => {
          this.tableData.forEach((row) => {
            if (row.selection === true) {
              this.$refs.multipleTable.toggleRowSelection(row, true);
            }
          });
        });
      });
    },
    getOptions() {
      getAreaOptions().then((result) => {
        this.options.areaOptions = result.data;
        this.options.areaOptions.unshift({ value: "", label: "全部" });
      });
    },
  },
};
</script>

<style scoped></style>
